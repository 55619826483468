import { getRequest, postRequest, severityCode } from "./httpClient";

const END_POINT = "asset";

const finishChunkedFile = async (id, fileName, cunckCount) => {
  console.log("asset.api finishChunkedFile ", id, fileName, cunckCount);
  const response = await postRequest(`${END_POINT}/chunk/${id}/finish`, { kind: "vabi", fileName: fileName, totalCunckCount: cunckCount });
  return response;
};

const getUploadStatus = async number => {
  const response = await getRequest(`${END_POINT}/vabi/uploadstatus`, { number: number });
  return response;
};

const complexesToCll = async (importId, partyPrefix, partyName) => {
  const response = await getRequest(`${END_POINT}/vabi/complexesToCll`, { importId: importId, partyPrefix: partyPrefix, partyName: partyName });
  return response;
};

export { finishChunkedFile, getUploadStatus, complexesToCll, severityCode };
